/**
 * Content
 */


$content-padding: 10px 0;


.content {
    padding: $content-padding;
    @include breakpoint(m) {
        padding: 40px 70px;
    }
    @include breakpoint(l) {
        padding: 40px 0;
    }
}


body.single {

    .content {
        @include breakpoint(m) {
            padding: 40px 70px;
        }
        @include breakpoint(l) {
            padding: 40px 0;
        }
    }

}

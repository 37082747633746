/**
 * Nav
 */


$nav-x-padding: 10px;
$navbar-border-color: #003866;
$navbar-bg-color: #00467f;



.nav {
    position: relative;
    padding: 0 $nav-x-padding;
    box-shadow: 0 0 4px 0 #ddd;
    background-color: #fff;
    color: $body-color;
    font-weight: 700;
    font-size: .875em; // 14px
    line-height: 1.43; // 20px
    font-family: $font-family-serif;
}


    .navbar {
        @include breakpoint(l) {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: center;
            align-content: center;
        }


        &Header {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: center;
            align-content: center;
        }

                &Logo {
                    display: block;
                    width: 146px;
                    height: 68px;
                    padding: 10px 0;
                    color: inherit;
                    text-decoration: none;
                    @include breakpoint(s) {
                        width: 192px;
                        height: 82px;
                    }
                    @include breakpoint(l) {
                      height: 102px;
                      padding: 20px 0;
                    }

                    img {
                        display: block;
                        width: 100%;
                        height: auto;
                    }
                }

                &ToggleNavbarMain {
                    width:  16px;
                    height: 12px;
                    border: 0;
                    border-radius: 0;
                    outline: 0;
                    padding: 0;
                    margin: 0;
                    background-color: transparent;
                    cursor: pointer;
                    @include breakpoint(l) {
                        display: none;
                    }

                    > span {
                        display: block;
                        width: 100%;
                        height: 2px;
                        margin: 3px 0 0;
                        background-color: $color-primary;
                        color: transparent;

                        &:first-child {
                            margin: 0;
                        }
                    }
                }

        &Main {
            display: none;
            margin-right: -($nav-x-padding + $layout-padding);
            margin-left: -($nav-x-padding + $layout-padding);
            background-color: $navbar-bg-color;
            @include breakpoint(l) {
                display: flex !important;
                flex-flow: row wrap;
                align-items: center;
                align-content: center;
                margin-right: 0;
                margin-left: 0;
                background: transparent;
            }
        }


            &Menu {
                list-style: none;
                padding: 0;
                margin: 0;
                @include breakpoint(l) {
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: space-between;
                    align-items: center;
                    align-content: center;
                }

                    li {
                        position: relative;
                        padding: 0;
                        border-top: 1px solid $navbar-border-color;
                        @include breakpoint(l) {
                            border-top: 0;
                            padding: 41px 0;
                        }

                        &:first-child {
                            border-top: 0;
                        }

                        a {
                            position: relative;
                            display: block;
                            padding: 10px 20px;
                            color: #fff;
                            text-decoration: none;
                            @include breakpoint(l) {
                              padding: 10px;
                              color: inherit;
                            }

                            &:hover {
                              text-decoration: none;
                            }
                        }

                        ul {
                            display: none;
                            list-style: none;
                            border-top: 1px solid $navbar-border-color;
                            padding: 0;
                            margin: 0;
                            background-color: $navbar-bg-color;
                            color: #fff;

                            @include breakpoint(l) {
                                position: absolute;
                                top: 100%;
                                left: 0;
                                z-index: 101;
                                width: 200px;
                                border: 1px solid $navbar-border-color;
                                box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .33);
                            }

                            li {
                              @include breakpoint(l) {
                                padding: 0;
                              }

                              a {
                                padding: 10px 20px 10px 40px;
                                @include breakpoint(l) {
                                  padding: 10px;
                                }
                              }

                              ul {
                                top: 0;
                                left: 100%;
                                margin-top: -1px;
                                li {
                                  a {
                                    padding: 10px 20px 10px 60px;
                                    @include breakpoint(l) {
                                      padding: 10px;
                                    }
                                  }

                                  ul {
                                    li {
                                      a {
                                        padding: 10px 20px 10px 80px;
                                        @include breakpoint(l) {
                                          padding: 10px;
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                        }
          }

          > li {
            > a {
              display: block;
            }
          }

          li.menu-item-has-children {
            > a {
              &:after {
                content: '\f107';
                font-family: FontAwesome;
                position: absolute;
                top: 0;
                right: 20px;
                display: block;
                width: 16px;
                font-size: 24px;
                line-height: 40px;
                text-align: center;
                transition: transform .25s linear;
                @include breakpoint(l) {
                  content: '\f105';
                  right: 10px;
                }
              }
            }
          }

          > li.menu-item-has-children {
            > a {
              &:after {
                @include breakpoint(l) {
                  display: none;
                }
              }
            }
          }

          li:hover {
            > a {
              &:after {
                transform: rotate(180deg);
                transition: transform .25s linear;
              }
            }

            > ul {
              display: block;
            }
          }
        }


        &Divider {
            display: block;
            background-color: $navbar-border-color;
            width: auto;
            height: 2px;
            margin: 10px 0;
            @include breakpoint(l) {
                background-color: #ebebeb;
                width: 2px;
                height: 30px;
                margin: 0 10px;
            }
        }


        &Auth {
            > li {
                > a {
                    color: #fff;
                    font-family: sans-serif;
                    text-transform: uppercase;
                    @include breakpoint(l) {
                        color: $color-primary;
                        &:hover,
                        &:active {
                          color: $color-primary;
                        }
                    }

                    &:hover,
                    &:active {
                        text-decoration: underline;
                    }
                }
            }
        }


                &ToggleNavbarSearchForm {
                    display: none !important;
                    width:  20px;
                    height: 20px;
                    border: 0;
                    border-radius: 0;
                    outline: 0;
                    padding: 0px;
                    margin: 0;
                    background: transparent url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA4BpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo1MzYxMGYyYS1mZjk1LTRiZTMtYjExMS1jOGFlZDc0OGQwNzQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OUQwNEE2RTc2NTREMTFFNjhFNTNCM0FEQUE0Mzk5M0YiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OUQwNEE2RTY2NTREMTFFNjhFNTNCM0FEQUE0Mzk5M0YiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoyZTNiMzA2Yy1mY2VmLTQ5YzktYmZlNy05M2YxNzEyOWEzMDYiIHN0UmVmOmRvY3VtZW50SUQ9ImFkb2JlOmRvY2lkOnBob3Rvc2hvcDpmYzlmNTk3Mi05NjU4LTExNzktYmQ1Mi05N2MxYzhkNzg1OWQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7HctFIAAAA4ElEQVR42mJkcKtngAJfIC4EYlMo/zQQ9wHxFgY0wASlW4B4ExA7AjEPFIPYm6FyGJp8gLgaiH8BcT4QCwOxEJT9Cyrni6yJmUHZYQaQVgTiYiCeBMTfgfgHEJ8E4t9A7ALEEkC8ENkmEyh7MQMmmA2lTbD5iRBgRNd0BsqOxaI4BSkk4YAFiPuhIdUFxP+RnAkypBnKnoFqLSSeWqChhAscA2JPIP6E7KcaIPYH4v1A/BWKQewIqAYrIN4OxHzINuED/EC8DaoRZIAXMaH3EaQQycblxAY5TCPIiY0AAQYAP5Qs7xx7gmcAAAAASUVORK5CYII=') no-repeat scroll 50% 50%;
                    cursor: pointer;
                    @include breakpoint(l) {
                        display: block !important;
                    }
                }


                &Form {
                    padding: 10px 20px;

                    @include breakpoint(l) {
                        display: none;
                        position: absolute;
                        top: 100%;
                        right: 0;
                        z-index: 100;
                        width: 400px;
                        border: 1px solid $navbar-border-color;
                        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .33);
                        background-color: $navbar-bg-color;
                    }
                }
                &AuthForm {
                    display: none;
                    color: #fff;
                    button[type="submit"] {
                      background-color: $navbar-border-color;
                    }

                    a {
                      color: #fff;

                      &:hover,
                      &:active {
                        color: #fff;
                      }
                    }
                }
                &SearchForm {
                    display: block;
                    @include breakpoint-down(l) {
                        display: block !important;
                    }

          .searchForm {
            button[type="submit"] {
              background-color: $navbar-border-color;
            }
          }


                }
    }

/**
 * Social Feed
 */


.socialFeedSection {
    .socialFeedEntries {
        margin-top: -10px;
        margin-right: -($layout-padding);
        margin-bottom: -10px;
        margin-left: -($layout-padding);
        @include breakpoint(m) {
            // margin-top: -10px;
            // margin-right: -($section-m-padding + $layout-padding);
            // margin-bottom: -10px;
            // margin-left: -($section-m-padding + $layout-padding);
        }
        @include breakpoint(l) {
            // margin-top: -10px;
            // margin-right: -($section-l-padding + $layout-padding);
            // margin-bottom: -10px;
            // margin-left: -($section-l-padding + $layout-padding);
        }
    }

        .entry {
            padding: 10px;

			.entryPane {
				position: relative;
				min-height: 193px;
				padding-top: 26px;
                padding-left: 50px;
				&:before {
					content: '';
					position: absolute;
					top: -1px;
					right: 0;
					left: 0;
					display: block;
					height: 6px;
					background-color: $color-primary;
				}
			}

                &Title {
                    position: relative;
                    &:after {
                        font-family: FontAwesome;
                        content: '\f099';
                        position: absolute;
                        top: 0;
                        left: -30px;
                        font-size: 18px;
                        color: #e1e1e1;
                    }
                }

				&Content {
					margin-bottom: 0;
				}

            &.color-default {
				.entryPane {
			    	&:before {
						background-color: $color-primary;
					}
				}
			}
			&.color-primary {
				.entryPane {
			    	&:before {
						background-color: $color-primary;
					}
				}
			}
			&.color-secondary {
				.entryPane {
					&:before {
			    		background-color: $color-secondary;
					}
				}
			}

			&.color-persimmon {
				.entryPane {
					&:before {
						background-color: $color-persimmon;
					}
				}
			}
			&.color-neon-carrot {
				.entryPane {
					&:before {
						background-color: $color-neon-carrot;
					}
				}
			}
			&.color-santa-fe {
				.entryPane {
					&:before {
						background-color: $color-santa-fe;
					}
				}
			}
			&.color-akaroa {
				.entryPane {
					&:before {
						background-color: $color-akaroa;
					}
				}
			}
			&.color-danube {
				.entryPane {
					&:before {
						background-color: $color-danube;
					}
				}
			}
			&.color-golden-tainoi {
				.entryPane {
					&:before {
						background-color: $color-golden-tainoi;
					}
				}
			}
			&.color-yellow-green {
				.entryPane {
					&:before {
						background-color: $color-yellow-green;
					}
				}
			}
			&.color-oregon {
				.entryPane {
					&:before {
						background-color: $color-oregon;
					}
				}
			}
			&.red {
				.entryPane {
					&:before {
						background-color: #f75c58;
					}
				}
			}
			&.orange {
				.entryPane {
					&:before {
						background-color: #F59D40;
					}
				}
			}
			&.brown {
				.entryPane {
					&:before {
						background-color: #b47751;
					}
				}
			}
			&.light-brown {
				.entryPane {
					&:before {
						background-color: #D8CFB6;
					}
				}
			}
			&.blue {
				.entryPane {
					&:before {
						background-color: #7295C8;
					}
				}
			}
			&.blue {
				.entryPane {
					&:before {
						background-color: #7295C8;
					}
				}
			}
			&.yellow {
				.entryPane {
					&:before {
						background-color: #FACD6B;
					}
				}
			}
			&.light-green {
				.entryPane {
					&:before {
						background-color: #B7DD76;
					}
				}
			}
        }
}

/**
 * Forms
 */


form {}


fieldset {
    border: 0;
    outline: 0;
    padding: 0;
    margin: 0;
}


.field {
    margin: 10px 0 0;

    &:first-child {
        margin-top: 0;
    }
}


input[type="text"],
input[type="email"],
input[type="password"],
select,
input[type="search"] {
    width: 100%;
    height: 40px;
    border: 1px solid $border-color;
    border-radius: 0;
    border-radius: 4px;
    outline: none;
    padding: 10px;
    margin: 0;
    font: inherit;

    &:focus {
        box-shadow: 0 0 4px 0 $color-primary;
    }
}
textarea {
    resize: none;
    width: 100%;
    border: 1px solid #ebebeb;
    border-radius: 4px;
    padding: 10px;
}

input[type="submit"],
button[type="submit"] {
    width: 120px;
    height: 40px;
    border: 0;
    border-radius: 4px;
    outline: 0;
    padding: 0;
    margin: 0;
    background-color: $color-primary;
    color: #fff;
    font: inherit;
    cursor: pointer;

    &:focus {
        box-shadow: 0 0 4px 0 $color-primary;
    }
}

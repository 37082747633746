/**
 * Date Selector
 */


.dateSelector {
    color: $heading-color;
    font-weight: 700;
    font-family: $font-family-serif;
}

/**
 * Search Form
 */


.searchForm {
    display: flex;
    flex-flow: row nowrap;

    input[type="search"] {
		-webkit-appearance: none;
	       -moz-appearance: none;
	            appearance: none;
        flex: 1 1 auto;
        height: 40px;
        border: 1px solid $border-color;
        border-radius: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        outline: none;
        padding: 10px;
        margin: 0;
        font: inherit;

        &:focus {
            box-shadow: 0 0 4px 0 $color-primary;
        }
    }

    button[type="submit"] {
        flex: 0 0 80px;
        max-width: 80px;
        width: 80px;
        height: 40px;
        border: 0;
        border-radius: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        outline: 0;
        padding: 0;
        margin: 0;
        background-color: $color-primary;
        color: #fff;
        font: inherit;
        cursor: pointer;

        &:focus {
            box-shadow: 0 0 4px 0 $color-primary;
        }
    }
}

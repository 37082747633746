/**
 * Config
 */


//
// Global
//


//
// Breakpoints
//
$breakpoints : (
    xs: 0,
    s: 480px,
    m: 720px,
    l: 1000px,
    xl: 1200px,
    xxl: 1360px
);


$border-color: #ebebeb;


$color-primary: #00467f;
$color-secondary: #569bbe;


// http://chir.ag/projects/name-that-color/
$color-persimmon: #ff5c5c;
$color-neon-carrot: #ff9e3d;
$color-santa-fe: #b47751;
$color-akaroa: #d9ceb4;
$color-danube: #6699cc;
$color-golden-tainoi: #ffcc66;
$color-yellow-green: #b8db71;
$color-oregon: #933c06;


$font-family-serif: 'Serifa Std', serif;
$font-family-sans-serif: 'Arial', sans-serif;

/**
 * Tables
 */


table {
    width: 100%;
    border: 1px solid $border-color;
    border-collapse: collapse;
    word-wrap: break-word;

    tr {}

        td {
            border: 1px solid $border-color;
            padding: 10px;
        }
}


.table-container {
    overflow-x: auto;
    width: 100%;
}

/**
 * Breadcrumbs
 */


.breadcrumbs {
    list-style: none;
    padding: 0;
    margin: 0;
    color: $color-primary;
    font-weight: 700;
    font-size: 1em;
    font-family: $font-family-serif;

    li {
        display: inline-block;
        vertical-align: middle;

        &:after {
            content: '\0203A';
        }

        &:last-child {
            &:after {
                content: '';
            }
        }
    }
}

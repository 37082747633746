/**
 * Embeds
 */


img {
	max-width: 100%;
	height: auto;
	border: 0;
}


iframe {
	max-width: 100%;
}

.page-print {
    text-align: right;
    button {
        padding: 5px 5px;
        background-color: #fff;
    }
}

@media print {
    @page {
        size: A4;
        margin: 0;
        width: 210mm;
        height: 297mm;
    }

    html,body {
        background: #fff !important;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
    }

    header nav, footer, .social-bar, .secondaryNav, .side, .memberNav {
        display: none;
    }

    .pillarhero, .section { 
        width: 100%;
    }

    .pillarEntries { 
        width: 60%;
    }

    .nmaCategories {
        width: 60%;
    }

    .home {
        .homeHero {
            display: none;
        }

        .featuredSection {
            .entry {
                max-width: 100%;
            }
        }

        .pillarItemSection {
            height: 50mm;
            margin-bottom: 0mm;
            padding-bottom: 0mm;
        }

    }
}
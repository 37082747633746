/**
 * Footer
 */


.footer {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    background-color: $color-primary;
    color: #fff;
    font-size: 14px;
    line-height: 2.1428; // 30px
    text-align: center;
    @include breakpoint(s) {
        text-align: left;
    }

    &Logo {
        display: block;
        width: 112px;
        height: 36px;
        margin: auto;
    }


    &Address {
        font-style: normal;
        font-family: $font-family-serif;
        text-align: center;
        @include breakpoint(m) {
            text-align: left;
        }

        &Title {
            margin: 0;
            color: #fff;
            font-weight: 700;
            font-size: 1em;
            font-family: $font-family-sans-serif;
        }


        p {
            margin: 0;
        }


		a {
			color: inherit;
		}
    }


    &Navbar {
        &Menu {
            list-style: none;
            display: flex;
            flex-flow: row wrap;
            padding: 0;
            margin: -10px;
            font-weight: 700;
            font-family: $font-family-sans-serif;

            > li {
                padding: 10px;
                flex: 0 0 100%;
                max-width: 100%;
                @include breakpoint(s) {
                    flex: 0 0 50%;
                    max-width: 50%;
                }
                @include breakpoint(m) {
                    flex: 0 0 33.3333%;
                    max-width: 33.3333%;
                }
                @include breakpoint(l) {
                    flex: 0 0 25%;
                    max-width: 25%;
                }

                a {
                    color: inherit;
                    text-decoration: none;

                    &:hover,
                    &:active {
                        text-decoration: underline;
                    }
                }

                ul {
                    font-weight: 300;
                    font-family: $font-family-serif;
                    list-style: none;
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }
}

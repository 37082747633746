/**
 * Side
 */


$side-pane-padding: 20px;


.side {}


    .sidePane {
        height: 100%;
        padding: $side-pane-padding;
        background-color: #fff;
    }

        .sidePaneHeader {
            margin: 0 0 30px;
        }

            .sidePaneTitle {
                font-size: 1.125em;
                font-family: $font-family-sans-serif;
            }

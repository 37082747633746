/**
 * Core / Type
 */


$root-font-size: 100%;


$body-background-color: #efefef;
$body-color: #646464;
$body-font-weight: 300;
$body-font-size: 1em;
$body-line-height: 1.5;
$body-font-family: $font-family-sans-serif;


$heading-margin-top: 0;
$heading-margin-bottom: 2rem;
$heading-color: #2f2f2f;
$heading-font-size: 1rem;
$heading-line-height: 1.33;
$heading-font-family: serif;
$h1-font-size: 2rem;
$h2-font-size: 1.75rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.25rem;
$h5-font-size: 1.125rem;
$h6-font-size: 1rem;


$hr-border: 1px solid #ebebeb;
$hr-padding: 0;
$hr-margin-top:  0;
$hr-margin-bottom: 2rem;


$paragraph-margin-top: 0;
$paragraph-margin-bottom: 2rem;


$list-padding: 0 0 0 2rem;
$list-margin-top: 0;
$list-margin-bottom: 2rem;


$desclist-padding: 0;
$desclist-margin-top: 0;
$desclist-margin-bottom: 2rem;


$blockquote-border: 1px solid #ebebeb;
$blockquote-padding: 0 0 0 2rem;
$blockquote-margin: 0 2rem 2rem;


$figure-border: 0;
$figure-padding: 0;
$figure-margin: 0 0 2rem;


html {
    font-size: $root-font-size;
}


body {
    background-color: $body-background-color;
    color: $body-color;
    font-weight: $body-font-weight;
    font-size: $body-font-size;
    line-height: $body-line-height;
    font-family: $body-font-family;
    word-wrap: break-word;
}


//
// Headings
//
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: $heading-margin-top;
    margin-bottom: $heading-margin-bottom;
    color: $heading-color;
    font-family: $font-family-serif;
}
h1, .h1 {
    font-size: $h1-font-size;
}
h2, .h2 {
    font-size: $h2-font-size;
}
h3, .h3 {
    font-size: $h3-font-size;
}
h4, .h4 {
    font-size: $h4-font-size;
}
h5, .h5 {
    font-size: $h5-font-size;
}
h6, .h6 {
    font-size: $h6-font-size;
}


//
// Horizontal Rules
//
hr {
    border: 0;
    border-bottom: $hr-border;
    padding: $hr-padding;
    margin-top: $hr-margin-top;
    margin-bottom: $hr-margin-bottom;
}


//
// Paragraphs
//
p {
    margin-top: $paragraph-margin-top;
    margin-bottom: $paragraph-margin-bottom;
}


//
// Lists
//
ul,
ol {
    padding: $list-padding;
    margin-top: $list-margin-top;
    margin-bottom: $list-margin-bottom;


    li {}


    ul,
    ol {
        margin-bottom: 0;
    }
}


//
// Desc Lists
//
dl {
    padding: $desclist-padding;
    margin-top: $desclist-margin-top;
    margin-bottom: $desclist-margin-bottom;


    dt {}
    dd {}
}


//
// Desc Lists
//


//
// Blockquotes
//
blockquote {
    border-left: $blockquote-border;
    padding: $blockquote-padding;
    margin: $blockquote-margin;
}


//
// Figures
//
figure {
    border: $figure-border;
    padding: $figure-padding;
    margin: $figure-margin;

    h1, h2, h3, h4, h5, h6,
    p {
        margin: 0 0 1rem;
        font-weight: 700;
    }

    caption {
        margin: 1rem 0 0;
        font-size: .875em;
    }
}


//
// Links
//
a {
    color: #00467f;
    text-decoration: none;

    &.color-primary {
        color: $color-primary;
    }
    &.color-secondary {
        color: $color-secondary;
    }
    &.color-persimmon {
        color: $color-persimmon;
    }
    &.color-neon-carrot {
        color: $color-neon-carrot;
    }
    &.color-santa-fe {
        color: $color-santa-fe;
    }
    &.color-akaroa {
        color: $color-akaroa;
    }
    &.color-danube {
        color: $color-danube;
    }
    &.color-golden-tainoi {
        color: $color-golden-tainoi;
    }
    &.color-yellow-green {
        color: $color-yellow-green;
    }
    &.color-oregon {
        color: $color-oregon;
    }

    &:hover,
    &:active {
        text-decoration: underline;
    }
}



//
// Alignment
//
.alignleft {
	float: left;
	margin: 0.375em 1.75em 1.75em 0;
}

.alignright {
	float: right;
	margin: 0.375em 0 1.75em 1.75em;
}

.aligncenter {
	clear: both;
	display: block;
	margin: 0 auto 1.75em;
}

blockquote.alignleft {
	margin: 0.3157894737em 1.4736842105em 1.473684211em 0;
}

blockquote.alignright {
	margin: 0.3157894737em 0 1.473684211em 1.4736842105em;
}

blockquote.aligncenter {
	margin-bottom: 1.473684211em;
}


blockquote:before,
blockquote:after,
.entryContent:before,
.entryContent:after,
.comment-content:before,
.comment-content:after,
.entryContent > article:before,
.entryContent > article:after {
	content: "";
	display: table;
}
blockquote:before,
blockquote:after,
.entryContent:before,
.entryContent:after,
.comment-content:before,
.comment-content:after,
.entryContent > article:before,
.entryContent > article:after {
	clear: both;
}

/**
 * Mixins
 */


@mixin breakpoint($breakpoint) {
    @media (min-width: map-get($breakpoints, #{$breakpoint}))  {
        @content;
    }
}


@mixin breakpoint-down($breakpoint) {
    @media (max-width: #{map-get($breakpoints, #{$breakpoint}) - 1px})  {
        @content;
    }
}


@mixin breakpoint-up($breakpoint) {
    @media (min-width: map-get($breakpoints, #{$breakpoint})) {
        @content;
    }
}

/**
 * Media Feed
 */


.mediaFeedSection {
    .mediaFeedEntries {
        margin-top: -10px;
        margin-right: -($layout-padding);
        margin-bottom: -10px;
        margin-left: -($layout-padding);
        @include breakpoint(m) {
            // margin-top: -10px;
            // margin-right: -($section-m-padding + $layout-padding);
            // margin-bottom: -10px;
            // margin-left: -($section-m-padding + $layout-padding);
        }
        @include breakpoint(l) {
            // margin-top: -10px;
            // margin-right: -($section-l-padding + $layout-padding);
            // margin-bottom: -10px;
            // margin-left: -($section-l-padding + $layout-padding);
        }
    }

        .entry {
            padding: 10px;

			&Title {
				overflow: hidden;
				text-overflow: ellipsis;
				word-wrap: normal;
				white-space: nowrap;
			}

			&Content {
				margin-bottom: 0;
			}
        }

}


.text-orange {
    color: #EA9741;
}

.svg-container {
    max-width: 1000px;
    margin: auto;
	width: 100%;
    position: relative;

	#States {
		cursor: zoom-in;
		.state.active:hover {
			cursor: zoom-out;
		}
	}
	#MapContainer {
		-webkit-transition: all .75s linear;
		-moz-transition: all .75s linear;
		-o-transition: all .75s linear;
		transition: all .75s linear;
	}
	h3, h4 {
		color: #fff;
		font-family: $font-family-sans-serif;
		margin: 0;

	}
	h3 {
		font-size: 1.17em;
	}
	h4 {
		font-size: 1em;
	}
	.grey{
		fill:#F1F2F2;
	}
	.grey2{
		fill:#E6E7E8;
	}
	.white{
		fill:#FFFFFF;
	}
	.blue{
		fill:#3A6F96;
		stroke:#FFFFFF;
		stroke-width:0.5;
		stroke-miterlimit:10;
	}
	.orange,.active{
		fill:#EA9741;
		stroke:#FFFFFF;
		stroke-width:0.5;
		stroke-miterlimit:10;
	}

}
.map-state-popup-large {
    position: absolute;
    opacity: 0;
    transform: translateY(-50%);
    z-index: 100;
    display: inline-block;
    color: white;
    background-color: rgb(217, 82, 71);
    padding: 15px;
    border-radius: 15px;
    font-size: .75em;
	line-height: 1em;
	top: -9999em;
	-webkit-transition: opacity .75s linear;
	-moz-transition: opacity .75s linear;
	-o-transition: opacity .75s linear;
	transition: opacity .75s linear;
	@include breakpoint(m) {
		line-height: 1.5em;
	}
	&.visible {
		right: 2em;
		left: 50%;
		opacity: 1;
		top: 100%;
		@include breakpoint(m) {
			top: 50%;
		}
	}
    -webkit-box-shadow: 10px 10px 90px -12px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 90px -12px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 10px 90px -12px rgba(0, 0, 0, 0.75);

	p {
		margin-bottom: .5em;
		@include breakpoint(m) {
			margin-bottom: 2rem;
		}

	}

}

.map-state-popup-mini {
    position: absolute;
    top: -9999em;
    left: -9999em;
    z-index: 100;
    display: inline-block;
    width: 225px;
    color: white;
    background-color: rgb(217, 82, 71);
    padding: 15px;
    border-radius: 15px;
    font-size: .75em;

    -webkit-box-shadow: 10px 10px 90px -12px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 90px -12px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 10px 90px -12px rgba(0, 0, 0, 0.75);
	&:after {
		content: "";
		position: absolute;
		top: -11px;
		left: -12px;
		border-width: 0 13px 25px;
		border-style: solid;
		border-color: rgb(217, 82, 71) transparent;
		display: block;
		width: 0;
	}
	&.right.top:after {
		transform: rotate(-45deg);
	}
	&.left.top:after {
		left: 210px;
		transform: rotate(45deg);
	}
	&.bottom.right {
		margin-top: -160px;
	}
	&.bottom.left {
		margin-top: -160px;
		margin-left: -10px;
	}
	&.right.bottom:after {
		transform: rotate(225deg);
		margin-top: -13px;
		top: 100%;
	}
	&.left.bottom:after {
		transform: rotate(135deg);
		margin-top: -13px;
		margin-left: -14px;
		top: 100%;
		left: 100%;
	}
	a {
		color: #EA9741;
	}

	p {
		margin: 5px 0;
	}
}
/**
 * Section
 */


$section-padding: 10px;
$section-s-padding: 10px;
$section-m-padding: 50px;
$section-l-padding: 20px;
$section-xl-padding: 20px;


$section-pane-padding: 20px;


.section {
    padding: $section-padding;
    @include breakpoint(s) {
        padding: $section-s-padding;
    }
    @include breakpoint(m) {
        padding: $section-m-padding;
    }
    @include breakpoint(l) {
        padding: $section-l-padding;
    }
    @include breakpoint(xl) {
        padding: $section-xl-padding;
    }

    &Header {
        margin: 0 0 30px;
    }

        &Title {
            @extend .clearfix;
            margin: 0;
            color: $heading-color;
            font-weight: 700;
            font-size: .875em;
            line-height: 1.1;

            .sectionMorelink {
                float: right;
                font-size: 1em;
            }

            .sectionMenu {
                @include breakpoint(m) {
                    float: right;
                }
            }

            .sectionLabel {
                display: block;
                margin-top: 10px;
                @include breakpoint(m) {
                    float: right;
                    margin-top: 0;
                }
            }
        }

        &Menu {
            list-style: none;
            padding: 0;
            margin: 5px -10px -5px;
            @include breakpoint(m) {
                margin-top: -5px;
            }

            li {
                display: inline-block;
                vertical-align: middle;
                border-right: 1px solid $border-color;

                a {
                    display: block;
                    padding: 5px 10px;
                    color: inherit;
                    text-decoration: none;

                    &:hover,
                    &:active {
                        text-decoration: underline;
                    }
                }

                &:last-child {
                    border-right: 0;
                }

                &.isActive {
                    color: $color-primary;

                    a {
                        &:hover,
                        &:active {
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        &Morelink {
            color: $heading-color;
            font-weight: 700;
            font-size: .875em;
            line-height: 1.1;

            &:hover,
            &:active {
                text-decoration: underline;
            }
        }

    &Footer {
        margin: 30px 0 0;
    }


    hr,
    p,
    ul, ol,
    dl,
    blockquote,
    figure {
        &:last-child {
            margin-bottom: 0;
        }
    }


    &.featuredSection,
    &.pillarSection,
    &.socialFeedSection,
    &.mediaFeedSection {
        background-color: #fff;
    }
}


    .sectionPane {
        height: 100%;
        padding: $section-pane-padding;
        background-color: #fff;
        @include breakpoint(m) {
            padding: 40px;
        }
    }

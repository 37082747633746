/**
 * Hero
 */


.hero {
    .heroEntry {
        position: relative;
        overflow: hidden;
        background-color: #e1e1e1;
        min-height: 450px;
		height: 450px !important;
        text-align: center;
        @include breakpoint(m) {
            min-height: 420px;
            text-align: left;
        }
        @include breakpoint(l) {
            min-height: 490px;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 2;
            width: 100%;
        	height: 100%;
        	background-color: rgba(255, 0, 0, .3);

            @include breakpoint(m) {
                transform: translateX(-33%) skewX(-25deg);
            }

            @include breakpoint(l) {
                transform: translateX(-50%) skewX(-25deg);
            }
        }

        &Thumbnail {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
        }

            img {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                z-index: 1;
                display: block;
                width: auto;
                margin: auto;
				height: 450px;
				object-fit: cover;

                @include breakpoint(s) {
                    width: 100%;
                    height: auto;
                }
            }

        &Body {
            // position: absolute;
            // top: 50%;
            // left: 0;
            // transform: translate(0, -50%);
            position: relative;
            z-index: 3;
            padding: 40px 20px 64px;
            color: #fff;
            text-shadow: 0 1px #000;
            text-align: center;
            @include breakpoint(s) {
                text-align: left;
            }
            @include breakpoint(m) {
                max-width: 60%;
            }
            @include breakpoint(l) {
                max-width: 50%;
            }
        }

            &Header {
                margin: 0 0 20px;
            }

                &Title {
                    margin: 0;
                    color: #fff;
                    font-size: 1.75em;
					line-height:1em;
                    @include breakpoint(m) {
                        font-size: 2em;
                    }
                    @include breakpoint(l) {
                        font-size: 2.375em;
                    }
                    @include breakpoint(xl) {
                        font-size: 2.625em;
                    }

                    a {
                        color: inherit;
                        text-decoration: none;

                        &:hover,
                        &:active {
                            text-decoration: underline
                        }
                    }
                }

            &Excerpt {
                @include breakpoint(m) {
                    font-size: 1.125em;
                }
                @include breakpoint(l) {
                    font-size: 1.25em;
                }
                @include breakpoint(xl) {
                    font-size: 1.375em;
                }
            }

            &Footer {
                margin: 20px 0 0;
            }

                &Morelink {
                    color: inherit;
                    font-weight: bold;
                    font-size: 1.125em;
                    text-decoration: none;

                    &:hover,
                    &:active {
                        text-decoration: underline;
                    }

                    @include breakpoint(m) {
                        font-size: 1.250em;
                    }
                    @include breakpoint(l) {
                        font-size: 1.5em;
                    }
                }


        &.color-primary {
            &:before {
                background-color: rgba($color-primary, .8);
            }
        }
        &.color-secondary {
            &:before {
                background-color: rgba($color-secondary, .8);
            }
        }
        &.color-persimmon {
            &:before {
                background-color: rgba($color-persimmon, .8);
            }
        }
        &.color-neon-carrot {
            &:before {
                background-color: rgba($color-neon-carrot, .8);
            }
        }
        &.color-santa-fe {
            &:before {
                background-color: rgba($color-santa-fe, .8);
            }
        }
        &.color-akaroa {
            &:before {
                background-color: rgba($color-akaroa, .8);
            }
        }
        &.color-danube {
            &:before {
                background-color: rgba($color-danube, .8);
            }
        }
        &.color-golden-tainoi {
            &:before {
                background-color: rgba($color-golden-tainoi, .8);
            }
        }
        &.color-yellow-green {
            &:before {
                background-color: rgba($color-yellow-green, .8);
            }
        }
        &.color-oregon {
            &:before {
                background-color: rgba($color-oregon, .8);
            }
        }
        &.red {
            &:before {
                background-color: rgba(247, 92, 88, .8);
            }
        }
        &.orange {
            &:before {
                background-color: rgba(245, 157, 64, .8);
            }
        }
        &.brown {
            &:before {
                background-color: rgba(180, 119, 81, .8);
            }
        }
        &.light-brown {
            &:before {
                background-color: rgba(216, 207, 182, .8);
            }
        }
        &.blue {
            &:before {
                background-color: rgba(114, 149, 200, .8);
            }
        }
        &.yellow {
            &:before {
                background-color: rgba(250, 205, 107, .8);
            }
        }
        &.light-green {
            &:before {
                background-color: rgba(183, 221, 118, .8);
            }
        }
    }


    // Slick
    .slick-arrow {
        position: absolute;
        top: 50%;
        z-index: 10;
        transform: translateY(-50%);
        border: 0;
        outline: 0;
        padding: 0;
        margin: 0;
        background-color: transparent;
        color: #fff;
        cursor: pointer;
        font: inherit;
        line-height: 1;

        &:before {
            font-family: FontAwesome;
            font-size: 60px;
            line-height: 1;
        }

        &.slick-prev {
            left: 20px;

            &:before {
                content: '\f104';
            }
        }

        &.slick-next {
            right: 20px;

            &:before {
                content: '\f105';
            }
        }
    }

    .slick-dots {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 30px;
        list-style: none;
        font-size: 0;
        padding: 0;
        margin: -5px;

        > li {
            display: inline-block;
            font-size: 1rem;
            padding: 5px;

            > button {
                display: block;
                width: 10px;
                height: 10px;
                border: 2px solid #fff;
                border-radius: 100%;
                outline: 0;
                padding: 0;
                margin: 0;
                background-color: transparent;
                color: transparent;
                cursor: pointer;
            }

            &.slick-active {
                > button {
                    background-color: #fff;
                }
            }
        }
    }


    &.homeHero {
        .heroEntry {
            &:before {
                background-color: rgba(60, 139, 179, .8);
            }
        }

            .heroEntryBody {
                @include breakpoint(m) {
					padding: 50px 30px;
                }
                @include breakpoint(l) {
					padding: 50px 100px;
                }
            }
    }

    &.pillarHero {
        .heroEntry {
		}

            .heroEntryBody {
                @include breakpoint(m) {
                    padding: 40px;
                }
                @include breakpoint(l) {
                    padding: 80px;
                }
            }
    }
}

/**
 * Core / Base
 */


html {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -ms-tap-highlight-color: rgba(0, 0, 0, 0);
}
*,
*:before,
*:after {
    box-sizing: inherit;
}


html {

}


body {
    margin: 0;
}


i, em {
    font-style: italic;
}

b, strong {
    font-weight: bold;
}

/**
 * Post
 */


.type-page,
.type-post {
    font-size: 1em;

    .entryThumbnail {
        position: relative;
        overflow: hidden;
        max-height: 270px;
		padding-bottom: 56.6666%;
        margin-bottom: 2rem;
        background-color: #e1e1e1;


        img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            max-width: 100%;
            height: auto;
            margin: auto;
        }
    }

    .entryBreadcrumbs {
        list-style: none;
        padding: 0;
        margin: 0 0 2rem;
        color: $color-primary;
        font-weight: 700;
        font-size: .875em;
        font-family: $font-family-serif;

        li {
            display: inline-block;
            vertical-align: middle;

            &:after {
                content: '\0203A';
            }

            &:last-child {
                &:after {
                    content: '';
                }
            }
        }

            a {
                color: $color-primary;
                text-decoration: none;

                &:hover,
                &:active {
                    text-decoration: underline;
                }
            }
    }

    .entryHeader {
        margin: 0 0 2rem;
    }

        .entryTitle {
            margin: 0;
            font-weight: 700;
            font-size: 1.375em;
            @include breakpoint(s) {
                font-size: 2em;
            }
        }

    .entryDate {
				position: relative;
				padding: 0 0 0 15px;
				margin: 0 0 2rem;
				font-size: .875em;
				line-height: 1.1;

				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					display: block;
					width: 10px;
					height: 10px;
					background: transparent url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA4RpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo1MzYxMGYyYS1mZjk1LTRiZTMtYjExMS1jOGFlZDc0OGQwNzQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OUE4NThCNUY2OTU3MTFFNkIzNTdFNzk2MUVCNUFEQ0EiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OUE4NThCNUU2OTU3MTFFNkIzNTdFNzk2MUVCNUFEQ0EiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MmFiYWQzNTItYjRkNi00MDU4LTgxYzAtOWMzYjRhOWFiYzIwIiBzdFJlZjpkb2N1bWVudElEPSJhZG9iZTpkb2NpZDpwaG90b3Nob3A6NDI1NzAzZGQtOWZjZC0xMTc5LThlNmUtYThjMzNkN2VlMGU5Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+O5nwegAAAMNJREFUeNp0kEEOATEYhduJA9i7BuIAWGHlCO0eic3UUq2Y2dj1CmLDCgcQHMYN6r34J2lk/Mk3b9q+vvz9dYxRsay1Y8gcdNS3nqAIIZy5yMS0huzAHrQE/hdyprQxZgTdgh5uv1VSMDUhd7Bk4gLklcl7Hyuj7OVsicY2uKn/daEnqzthaprMFhv4vEAfHLnjnNM/94acABNLhkjjquYxnh7NOcoIptL4VXwDsAEHPGqlk4FPIDPQFeODSTCduPgIMAD98EMrI+2EvQAAAABJRU5ErkJggg==') no-repeat scroll 0 0;
				}

        p {
            margin: 0;
        }
    }


    .entryContent {}


    .entryRelated {
        border-top: 1px solid $border-color;
        padding-top: 45px;
        margin-top: 45px;
        &Header {
            margin: 0 0 30px;
        }
            &Title {
                font-size: 1.125em;
                font-family: $font-family-sans-serif;
            }

        .entry {
            margin: 0 0 20px;
            &:last-child {
                margin-bottom: 0;
            }
            &Title {
                font-size: 1em;
            }
        }
    }


    .entryFooter {
        border-top: 1px solid $border-color;
        padding: 20px 0 0;
        margin: 2rem 0 0;
    }

        .entryShare {
            color: $color-primary;
            font-weight: 700;
            font-family: $font-family-serif;
            margin: -10px 0;

            a {
                display: inline-block;
                vertical-align: middle;
                color: inherit;
                text-decoration: none;
                margin: 10px;

                &:before {
                    font-family: FontAwesome;
                    font-size: 1.25em;
                }

                &[href*="youtube.com"] {
                    &:before {
                        content: '\f167'
                    }
                }
                &[href*="facebook.com"] {
                    &:before {
                        content: '\f09a';
                    }
                }
                &[href*="twitter.com"] {
                    &:before {
                        content: '\f099';
                    }
                }
            }
        }


    hr,
    p,
    ul, ol,
    dl,
    blockquote,
    figure {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.map-container {
	padding: 60px 250px;
}

.map-container .mapContent {
	background: #fff;
	padding: 30px;
}

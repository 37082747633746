/**
 * Dropdown
 */


.dropdown {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    color: $body-color;
    line-height: 1;

    &Toggle {
        border: 1px solid $border-color;
        border-radius: 0;
        outline: 0;
        padding: 10px;
        margin: 0;
        background-color: transparent;
        color: inherit;
        font: inherit;
        font-weight: 300;
        cursor: pointer;

        &:after {
            content: '\f078';
            display: inline-block;
            vertical-align: middle;
            border-left: 1px solid $border-color;
            padding: 10px 10px;
            margin: -10px -10px -10px 10px;
            color: $border-color;
            font-family: FontAwesome;
        }

        &:focus {
            box-shadow: 0 0 2px 0 $color-primary;
        }
    }

    &List {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        z-index: 101;
        list-style: none;
        min-width: 120px;
        border: 1px solid $border-color;
        border-radius: 0;
        padding: 0;
        margin: 0;
        background-color: #fff;

        li {
            padding: 10px;
            border-top: 1px solid $border-color;

            &:first-child {
                border-top: 0;
            }

            a {
                display: block;
            }
        }
    }

    &.open {
        .dropdownList {
            display: block;
        }
    }
}

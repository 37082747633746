/**
 * Layout
 */


$layout-width: auto;
$layout-padding: 10px;
$layout-margin: 10px;


@mixin make-container {
    .container {
        width: $layout-width;
        padding: $layout-padding;
        margin: auto;


        &-horizontal {
            padding-top: 0;
            padding-bottom: 0;
        }


        &-vertical   {
            padding-right: 0;
            padding-left: 0;
        }


        &-fixed {
            @include breakpoint(l) {
                width: 960px;
            }
            @include breakpoint(xxl) {
                width: 1260px;
            }
        }
    }
}


@mixin make-row() {
    .row {
        display: flex;
        flex-flow: row wrap;
        margin: -$layout-padding;
    }
}


@mixin make-col() {
    [class*="col-"] {
        flex: 0;
        padding: $layout-padding;
    }
    @each $breakpoint in map-keys($breakpoints) {
        @include breakpoint-up($breakpoint) {
            @for $i from 1 through 20 {
                $value : $i * 5;
                .col-#{$breakpoint}-#{$value} {
                    flex: 0 0 (#{$value + '%'});
                    max-width: (#{$value + '%'});
                }
            }
            .col-#{$breakpoint}-33 {
                flex: 0 0 33.333333333%;
                max-width: 33.33333333%;
            }
            .col-#{$breakpoint}-66 {
                flex: 0 0 66.66666666%;
                max-width: 66.66666666%;
            }
            .col-#{$breakpoint}-25 {
                flex: 0 0 25%;
                max-width: 25%;
            }
            .col-#{$breakpoint}-75 {
                flex: 0 0 75%;
                max-width: 75%;
            }
        }
    }
}


.col-size {
    width: 100%;
    @include breakpoint(s) {
        width: 50%;
    }
    @include breakpoint(l) {
        width: 33.3333%;
    }
    @include breakpoint(xl) {
        width: 25%;
    }
}


@include make-container();


@include make-row();


@include make-col();

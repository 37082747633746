/**
 * Pillar
 */


$pillar-item-padding: 20px;


.pillarItem {
    position: relative;
    height: 100%;
    padding: $pillar-item-padding;
    background-color: #fff;
    font-size: .875em;

    &Thumbnail {
        position: relative;
        border-bottom: 6px solid transparent;
        padding-bottom: 76.6666%;
        margin: (-$pillar-item-padding) (-$pillar-item-padding) 30px;
        background-color: #e1e1e1;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
		display: block;

        img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            max-width: 100%;
            max-height: 100%;
            margin: auto;
        }
    }

    &Header {
        margin: 0 0 20px;
    }

        &Title {
            margin: 0;
            font-size: 1.5714em;
            line-height: 1.3636;

            a {
                color: inherit;

                &:hover,
                &:active {
                    text-decoration: underline;
                }
            }
        }

    &Excerpt {
        padding-bottom: $pillar-item-padding + 21px; // 21px (pillarFooter Height)
    }

    &Footer {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        padding: $pillar-item-padding;
    }

        &Morelink {
            font-weight: 700;
            font-family: $font-family-serif;
        }


	&.color-persimmon {
		.pillarItemThumbnail {
			border-bottom-color: $color-persimmon;
		}
	}
	&.color-neon-carrot {
		.pillarItemThumbnail {
			border-bottom-color: $color-neon-carrot;
		}
	}
	&.color-santa-fe {
		.pillarItemThumbnail {
			border-bottom-color: $color-santa-fe;
		}
	}
	&.color-akaroa {
		.pillarItemThumbnail {
			border-bottom-color: $color-akaroa;
		}
	}
	&.color-danube {
		.pillarItemThumbnail {
			border-top-color: $color-danube;
		}
	}
	&.color-golden-tainoi {
		.pillarItemThumbnail {
			border-bottom-color: $color-golden-tainoi;
		}
	}
	&.color-yellow-green {
		.pillarItemThumbnail {
			border-bottom-color: $color-yellow-green;
		}
	}
	&.color-oregon {
		.pillarItemThumbnail {
			border-bottom-color: $color-oregon;
		}
	}
	&.red {
		.pillarItemThumbnail {
			border-bottom-color: #f75c58;
		}
	}
	&.orange {
		.pillarItemThumbnail {
			border-bottom-color: #F59D40;
		}
	}
	&.brown {
		.pillarItemThumbnail {
			border-bottom-color: #b47751;
		}
	}
	&.light-brown {
		.pillarItemThumbnail {
			border-bottom-color: #D8CFB6;
		}
	}
	&.blue {
		.pillarItemThumbnail {
			border-bottom-color: #7295C8;
		}
	}
	&.yellow {
		.pillarItemThumbnail {
			border-bottom-color: #FACD6B;
		}
	}
	&.light-green {
		.pillarItemThumbnail {
			border-bottom-color: #B7DD76;
		}
	}
}

.nmaСategories {
	h3 {
		margin-top: 3em;
	}
	.pillarItem {
		border: 2px solid #ebebeb;
		&Thumbnail {
			border-bottom-color: #fff;
			margin-bottom: 20px;
		}
		&Excerpt {
			padding-bottom: 0;
			fonr-sise: .75em;
		}
		.morelink {
			font-family: $font-family-serif;
		}
	}
}

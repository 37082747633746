/**
 * Social Links
 */


.socialLinks {
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: -10px;


    li {}


        a {
            display: block;
			padding: 10px;
            color: inherit;
            text-decoration: none;

            &:before {
                font-size: 1.75em;
                font-family: FontAwesome;
            }

            &[href*="youtube.com"] {
                &:before {
                    content: '\f167'
                }
            }
            &[href*="linkedin.com"] {
                &:before {
                    content: '\f0e1'
                }
            }
            &[href*="facebook.com"] {
                &:before {
                    content: '\f09a';
                }
            }
            &[href*="twitter.com"] {
                &:before {
                    content: '\f099';
                }
            }
        }
}

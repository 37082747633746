.social-bar {
    a {
        &:before {
            font-size: 1.75em;
            font-family: FontAwesome;
        }
        &[href*="linkedin.com"]:before {
            content: '\f0e1';
            color: #0077B5;
        }
        &[href*="youtube.com"]:before {
            content: '\f16a';
            color: #ff0000;
        }
        &[href*="facebook.com"]:before {
            content: '\f09a';
            color: #3B5998;
        }
        &[href*="instagram.com"]:before {
            content: '\f16d';
        }
        &[href*="twitter.com"]:before {
            content: '\f099';
            color: #1DA1F2;
        }
    }

    .row {
        margin: 0 auto;
        padding: 10px;
    }
    .socialProfiles {
        width: 100%;
        margin: 0 auto;
        list-style: none;
        display: flex;
        flex-flow: row wrap;
        padding: 0;
        font-family: $font-family-serif;
        font-size: .8em;
        font-weight: 700;

        @include breakpoint(l) {
            text-align: center;
        } 

        > li {
            padding: 10px;
            flex: 0 0 100%;
            max-width: 100%;
            @include breakpoint(s) {
                flex: 0 0 50%;
                max-width: 50%;
            }
            @include breakpoint(m) {
                flex: 0 0 50%;
                max-width: 50%;
            }
            @include breakpoint(l) {
                flex: 0 0 25%;
                max-width: 25%;
            }

            a {
            color: inherit;
            text-decoration: none;

            &:hover,
            &:active {
                text-decoration: underline;
                }
            }

            ul {
                font-weight: 300;
                font-family: $font-family-serif;
                list-style: none;
                padding: 0;
                margin: 0;
            }
        }

        .sub-menu {
            display: inline-flex;

            li {
                margin: 0 0 0 15px;

                span {
                    visibility: hidden;
                    font-size: 0px;
                }
            }
        }
    }
}
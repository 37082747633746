/**
 * Core / Utils
 */


//
// Visibility
//


@each $breakpoint in map-keys($breakpoints) {
    .hidden-#{$breakpoint}-up {
        @include breakpoint-up($breakpoint) {
            display: none !important;
        }
    }
    .hidden-#{$breakpoint}-down {
        @include breakpoint-down($breakpoint) {
            display: none !important;
        }
    }
}


//
// Clearfix
//


.clearfix {
    &:before,
    &:after {
        content: '';
        display: table;
    }
    &:after {
        clear: both;
    }
}


//
// Floating
//


.pull-left {
    float: left;
}


.pull-right {
    float: right;
}
.color-red {
	color: #f00;
}
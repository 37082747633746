/**
 * Main
 */


$main-pane-padding: 20px;
$main-pane-s-padding: 20px;
$main-pane-m-padding: 40px;
$main-pane-l-padding: 40px;
$main-pane-xl-padding: 40px;


.main {}


    .mainPane {
        padding: $main-pane-padding;
        @include breakpoint(s) {
            padding: $main-pane-padding;
        }
        @include breakpoint(m) {
            padding-right: $main-pane-m-padding;
            padding-left: $main-pane-m-padding;
        }
        background-color: #fff;


        &Header {
            margin-top: -$main-pane-padding;
            margin-right: -$main-pane-padding;
            margin-left: -$main-pane-padding;
            @include breakpoint(m) {
                margin-right: -$main-pane-m-padding;
                margin-left: -$main-pane-m-padding;
            }
        }
    }

/**
 * Archive
 **/


.archiveSection {
    &Thumbnail {
        position: relative;
        overflow: hidden;
        max-height: 270px;
		padding-bottom: 56.6666%;
        margin-bottom: 2rem;
        background-color: #e1e1e1;


        img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            max-width: 100%;
            height: auto;
            margin: auto;
        }
    }

    &Header {
        @extend .clearfix;
        margin: 0 0 2rem;
        font-size: .875em;
        line-height: 39px;

        .breadcrumbs {
            float: left;
        }

        .archiveDates {
            float: right;
            color: $heading-color;
            font-weight: 700;
            font-family: $font-family-serif;

            .dropdown {
                margin-left: 10px;
            }
        }
    }


    &Entries {}

        &Title {
            margin-bottom: 20px;
            font-size: 1.375em;
            @include breakpoint(m) {
                margin-bottom: 30px;
            }
            @include breakpoint(xl) {
                margin-bottom: 45px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }

        .entry {
            margin-bottom: 20px;
            font-size: 1em;
            @include breakpoint(m) {
                margin-bottom: 30px;
            }
            @include breakpoint(xl) {
                margin-bottom: 45px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }


    &Footer {
        border-top: 1px solid $border-color;
        padding-top: 20px;
        text-align: center;
        margin: 2rem 0 0;
    }

        &Morelink {
            font-weight: 700;
            font-family: $font-family-serif;
        }
}

/**
 * Footer Nav
 */


.secondaryNav {
    padding: 10px;
    background-color: #003866;
    color: #fff;
    font-size: .875em; // 14px
    line-height: 1.43; // 20px
}


    .secondaryNavbar {
        @include breakpoint(m) {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: center;
            align-content: center;
        }


            &Header {
                @include breakpoint(m) {
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: space-between;
                    align-items: center;
                    align-content: center;
                }
            }

                &Logo {
                    display: block;
                    width: 112px;
                    height: 36px;
                    color: inherit;
                    text-decoration: none;


                    img {
                        display: block;
                        width: 100%;
                        height: auto;
                    }
                }

                .copyright {
                    display: block;
                    font-family: $font-family-sans-serif;
                    text-align: center;
                    @include breakpoint(m) {
                        text-align: left;
                    }

                    p {
                        margin: 0;
                    }
                }

            &Main {
                display: none;
                @include breakpoint(m) {
                    display: block;
                }
            }


                &Menu {
                    list-style: none;
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    padding: 0;
                    margin: 0;


                    li {


                        a {
                            color: inherit;
                            text-decoration: none;
                        }
                    }
                }

                &Divider {
                    display: block;
                    background-color: $color-primary;
                    width: 2px;
                    height: 30px;
                    padding: 0;
                    margin: 0 20px;
                }
    }
